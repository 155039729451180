<script setup lang="ts">
import type { Movie } from '#gql/default'
import { URL_FILTER } from '@base/constants/url-filter'
import { MOVIE_PAGE_SECTION_IDS } from '@base/constants/movie'

interface Props {
  movie: Movie
}

defineProps<Props>()

const { t } = useI18n()

defineOptions({
  name: 'MovieCinemaListWithFilters',
})
</script>

<template>
  <section
    :id="MOVIE_PAGE_SECTION_IDS.SHOW_CINEMA_LIST"
    class="mt-8 flex flex-col space-y-2 md:mt-12"
  >
    <PageSectionHeading :title="t('playtimes')" />

    <CatalogCinemasForMovie
      :filters="[
        URL_FILTER.SEARCH,
        URL_FILTER.CITY,
        URL_FILTER.DATES,
        URL_FILTER.LANGUAGE,
        URL_FILTER.TECHNOLOGY,
      ]"
      :movie-id="movie.id"
      :booking-modal="false"
      :fetch-fallback-results="true"
      :link-params="[]"
      :enable-pagination="true"
      :render-above-the-fold="false"
    />
  </section>
</template>

<i18n>
de:
  playtimes: "Vorstellungen"
es:
  playtimes: "Horarios"
</i18n>
