<script setup lang="ts">
import type { Movie } from '#gql/default'

interface Props {
  movie: Movie
}

defineProps<Props>()

const { t, d } = useI18n()
const { icons } = useDesign()
const readMore = ref(false)

defineOptions({
  name: 'MovieCritique',
})
</script>

<template>
  <section
    :id="MOVIE_PAGE_SECTION_IDS.CRITIQUE"
    class="mt-8 flex flex-col space-y-2 md:mt-12"
  >
    <PageSectionHeading :title="t('movieInfo.critique')" />

    <div class="grid grid-cols-1 gap-8 md:grid-cols-3">
      <div class="prose dark:prose-invert col-span-2 max-w-none space-y-8">
        <div class="relative">
          <div
            :class="{
              'max-h-[100px] overflow-hidden': !readMore,
            }"
            class="flex flex-col"
          >
            <div v-html="movie.critique!.text" />

            <span v-text="t('published', { url: 'filmdienst.de' })" />

            <NuxtLink
              :to="movie.critique!.externalLink"
              :target="LINK_TARGET.BLANK"
              :external="true"
              class="underline"
            >
              <span v-text="movie.title" />
            </NuxtLink>

            <span
              v-text="
                `Von: ${movie.critique!.author} (${d(movie.critique!.date)})`
              "
            />
          </div>

          <div
            v-if="!readMore"
            class="from-body absolute bottom-0 h-12 w-full bg-gradient-to-t to-transparent"
          />
        </div>

        <div
          v-if="!readMore"
          class="bg-neutral-dark-1 mx-2 flex flex-col items-center justify-center space-y-3 rounded-md p-8 text-center md:mx-8"
        >
          <span class="text-2xl font-bold" v-text="t('spoiler.title')" />

          <span class="text-base" v-text="t('spoiler.description')" />

          <KButton :text="t('btn.read')" @click="readMore = true" />
        </div>
      </div>

      <div class="col-span-1 flex flex-col space-y-4">
        <span class="text-lg font-bold" v-text="t('filmDienst.about')" />

        <SvgLogoFilmdienst class="size-40" />

        <span class="prose dark:prose-invert max-w-none">
          Filmdienst.de, seit 1947 aktiv, bietet Filmkritiken,
          Hintergrundartikel und ein Filmlexikon zu neuen Kinofilmen aber auch
          Heimkino und Filmkultur. Ursprünglich eine Zeitschrift, ist es seit
          2018 digital und wird von der Katholischen Filmkommission für
          Deutschland betrieben.
        </span>

        <NuxtLink
          :external="true"
          :target="LINK_TARGET.BLANK"
          to="https://www.filmdienst.de/"
          class="button button-secondary button-base w-full"
        >
          <Icon :name="icons.LINK_EXTERNAL" />
          <span>filmdienst.de</span>
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<i18n>
de:
  movieInfo:
    critique: "Filmkritik"
  btn:
    read: "Filmkritik lesen"
  published: "Erschienen auf {url}"
  spoiler:
    title: "Vorsicht Spoiler-Alarm!"
    description: "Diese Filmkritik könnte Hinweise auf wichtige Handlungselemente enthalten."
  filmDienst:
    about: "Über Filmdienst.de"
</i18n>
