<template>
  <div data-cinemas-for-movie-catalog class="space-y-6">
    <LazyFilters v-if="filters?.length" v-bind="filterProps" />

    <Suspense>
      <div class="space-y-6">
        <template v-if="!hasPreviousPages && pending">
          <CinemasForMovieList :render-skeletons :movie-id />
        </template>

        <template v-else-if="hasPreviousPages || !pending">
          <EmptyResultHandler
            v-if="!pending && (hasFallbackResults || !cinemas?.length)"
            :type="EMPTY_RESULT_TYPE.CINEMAS"
            :has-filters-applied
            :url-prefix
            :has-fallback-results
            class="mt-4"
          />

          <CinemasForMovieList
            :cinemas
            :movie-id
            :dates="fetchParams.dates"
            :language-flags="fetchParams.languageFlags"
            :technology-flags="fetchParams.technologyFlags"
            :booking-modal
            :render-above-the-fold
            :link-params
          />

          <ButtonFetchMore
            v-if="showFetchMoreButton"
            :pending
            :enable-pagination-on-scroll
            class="col-span-full"
            @click="fetchMore"
            @fetch-on-scroll="fetchMore"
          />
        </template>
      </div>
      <template #fallback>
        <CinemasForMovieList :render-skeletons :movie-id />
      </template>
    </Suspense>
  </div>
</template>

<script setup lang="ts">
import type { LinkParam } from '#gql/default'

interface Props {
  search?: string
  dates?: string[]
  city?: string
  languageFlags?: string[]
  bookingModal?: boolean
  technologyFlags?: string[]
  movieId: string
  filters?: UrlFilter[]
  linkParams?: LinkParam[]
  urlPrefix?: string
  fetchFallbackResults?: boolean
  preserveFilters?: boolean
  itemsPerPage?: number
  enablePagination?: boolean
  enablePaginationOnScroll?: boolean
  renderAboveTheFold?: boolean
}

const props = defineProps<Props>()
const { t } = useI18n()

const renderSkeletons = computed(() => props.itemsPerPage ?? 4)
const showFetchMoreButton = computed(
  () => props.enablePagination && hasMorePages.value,
)

const {
  cinemas,
  pending,
  hasMorePages,
  hasFallbackResults,
  hasPreviousPages,
  hasFiltersApplied,
  fetchMore,
  fetchParams,
} = await useCinemasForMovie({
  movieId: props.movieId,
  fetchOperation: 'FetchCinemasForMovie',
  filters: props.filters,
  urlPrefix: props.urlPrefix,
  fetchFallbackResults: props.fetchFallbackResults,
  preselected: {
    movieId: props.movieId,
    search: props.search,
    city: props.city,
    dates: props.dates,
    languageFlags: props.languageFlags,
    technologyFlags: props.technologyFlags,
  },
  first: props.itemsPerPage,
})

// @TODO should be refactored into a useFilters composable
const filterProps = computed(() => ({
  urlPrefix: props.urlPrefix,
  fetchComponent: resolveComponent('FiltersDataProviderCinemasForMovie'),
  fetchParams: {
    movieId: props.movieId,
  },
  filters: props.filters,
  searchPlaceholder: t('placeholder.search'),
  preselected: {
    city: props.city,
    dates: props.dates,
    search: props.search,
    languageFlags: props.languageFlags,
    technologyFlags: props.technologyFlags,
  },
}))

defineOptions({
  name: 'CatalogCinemasForMovie',
})
</script>

<i18n>
de:
  placeholder:
    search: "Suche nach Kinos"
es:
  placeholder:
    search: "Buscar cines"
</i18n>
